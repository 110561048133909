// This controller facilitates sending custom events to Google Analytics as a
// result of user interactions with the page.
//
// Usage:
//
// <body data-controller="analytics-event">
//   <a href="..."
//      data-action="analytics-event#send"
//      data-analytics-event="<%= {someKey: "some_value"}.to_json %>"
//   >
//     Hello World
//   </a>
// </body>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  send(event) {
    this.snoop(`analytics-event#send: ${event.target.outerHTML.substring(0, 99)}`)
  }

  // private

  snoop(text) {
    if (["staging", "production"].includes(window.rollbarEnvironment)) {
      window.rollbar?.info(text)
    }
  }
}
